/*	
	CUSTOM JS
	_______________________
*/

$(document).ready(function()
{
	// alert("ready");
	
	/**
	 * DELETE FORMS
	 * ------------
	 * 
	 * Delete form confirmation.
	 */
	// $("form.delete").attr("method", "POST");
	
	// $("input.delete").on("click", function(submitted)
	// {
 //        // return confirm("This will delete your existing billing address information. Do you wish to continue?");
		
	// 	submitted.preventDefault();
	// 	var form = $(this).parents("form");
		
	// 	swal
	// 	({
	// 		title: "Replace Address?",
	// 		text: "This will replace your existing billing address information with that of your shipping address.",
	// 		type: "warning",
	// 		showCancelButton: true,
	// 		confirmButtonColor: "#37cdb2",
	// 		confirmButtonText: "Replace Address",
	// 		closeOnConfirm: false
	// 	},
	// 	function(confirmed)
	// 	{
	// 		if(confirmed)
	// 		{
	// 			// swal("Success", "Your shipping address will now be used for billing.", "success");
	// 			form.submit();
	// 			// alert(form.attr("class"));
	// 		}
	// 	});
 //    });
    
    
    /**
     * HOME.INDEX
     * -------------
     */
    if($("#hours").length > 0)
	{
		function open()
		{
			$("#hours").removeClass('closed');
			$("#hours").addClass('open');
			$("#readout").removeClass('cross');
			$("#readout").addClass('tick');
			$("#readout").html('During business hours');
			$("a.contact_number").removeClass('disabled');
		}
		function close()
		{
			$("#hours").removeClass('open');
			$("#hours").addClass('closed');
			$("#readout").removeClass('tick');
			$("#readout").addClass('cross');
			$("#readout").html('Outside business hours');
			$("a.contact_number").addClass('disabled');
		}
		
		function checkBusinessHours()
		{
		    var utc = moment.utc();
			var weekday = false;
			var business = false;
			var time_open = $("#hours").data('open');
			var time_closed = $("#hours").data('closed');
			var tz = $("#hours").data('tz');
			var nz = moment.utc().utcOffset('+' + tz + '00');
			var nz_hour = nz.hour();
			
			// console.log(utc.format());
			// console.log(tz);
			// console.log(nz.format());
			// console.log(nz_hour);
			// console.log(utc.toISOString());
			// console.log(nz.isoWeekday());
			// console.log(time_open);
			
			if(nz.isoWeekday() < 6)
			{
				weekday = true;
			}
			if(nz_hour >= time_open && nz_hour < time_closed)
			{
				business = true;
			}
			if(weekday && business)
			{
				open();
			}
			else
			{
				close();
			}
		}
		
		checkBusinessHours();
		
		setInterval(checkBusinessHours, 60000);
	}
	
	
	/**
     * CART.INDEX
     * -------------
     */
    if($("input#cart_empty").length > 0)
    {
    	$("input#cart_empty").on("click", function(click)
	    {
			click.preventDefault();
			var form = $(this).parent("form");
			
			swal
			({
				title: "Empty Cart",
				text: "Are you sure you wish to empty your cart?",
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#0f9092",
				confirmButtonText: "Empty",
				closeOnConfirm: false,
				html: false
			},
			function(confirmed)
			{
				if(confirmed)
				{
					form.submit();
				}
			});
		});
    }
    
    
    /**
     * CART.CHECKOUT
     * -------------
     */
    if($("form#orders_store").length > 0)
    {
    	// Get the order subtotal and global shipping flat rate.
		var subtotal = new Number($("h3.price").data("subtotal"));
		var shipping = new Number($("h3.price").data("shipping"));
		
    	// Format a decimal as currency.
    	Number.prototype.formatMoney = function(c, d, t)
		{
			var n = this, 
			c = isNaN(c = Math.abs(c)) ? 2 : c, 
			d = d == undefined ? "." : d, 
			t = t == undefined ? "," : t, 
			s = n < 0 ? "-" : "", 
			i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))), 
			j = (j = i.length) > 3 ? j % 3 : 0;
			return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		};
		// Round a number up to two decimal places and then format it as NZ currency..
		function nzd(value)
		{
			var rounded = (Math.ceil(100 * value) + Math.ceil(100 * value - Math.ceil(100 * value))) / 100;
			return '$' + rounded.formatMoney(2);
		}
    	
	    var check_shipping_radios = function()
	    {
	    	// Determine which shipping address radio is checked.
		    if($("input#shipping_existing").prop("checked"))
			{
				get_existing();
				recent_shipping = 'existing';
				
				$(".hidden_fields.shipping").hide(); // Hide the new-address text fields.
				// $("input#shipping_existing").parents("div.radio").css("background-color", "white"); // Highlight the radio's parent div.
				// $("input#shipping_new").parents("div.radio").css("background-color", "#f2f2f2"); // Darken the other radio's parent div.
				$("input.shipping_new, select.shipping_new").prop("disabled", true); // Disable the new-address inputs.
				$("select#existing_shipping_address").prop("disabled", false); // Enable the existing-address inputs.
				$("span.required.shipping").hide(); // Hide required-field indicators.
			}
			if($("input#shipping_new").prop("checked"))
			{
				get_new();
				recent_shipping = 'new';
				
				$(".hidden_fields.shipping").show(); // Show the new-address text fields.
				// $("input#shipping_new").parents("div.radio").css("background-color", "white"); // Highlight the radio's parent div.
				// $("input#shipping_existing").parents("div.radio").css("background-color", "#f2f2f2"); // Darken the other radio's parent div.
				$("input.shipping_new, select.shipping_new").prop("disabled", false); // Enable the new-address inputs.
				$("select#existing_shipping_address").prop("disabled", true); // Disable the existing-address inputs.
				$("span.required.shipping").show(); // Show required-field indicators.
			}
	    }
	    var check_billing_radios = function()
	    {
	    	// Determine which billing address radio is checked.
			if($("input#billing_same").prop("checked"))
			{
				recent_billing = 'same';
				
				$(".hidden_fields.billing").hide(); // Hide the new-address text fields.
				// $("input#billing_same").parents("div.radio").css("background-color", "white"); // Highlight the radio's parent div.
				// $("input#billing_existing, input#billing_new").parents("div.radio").css("background-color", "#f2f2f2"); // Darken the other radios' parent div.
				$("input.billing_new, select.billing_new").prop("disabled", true); // Disable the new-address inputs.
				$("select#existing_billing_address").prop("disabled", true); // Disable the existing-address inputs.
				$("span.required.billing").hide(); // Hide required-field indicators.
			}
		    if($("input#billing_existing").prop("checked"))
			{
				recent_billing = 'existing';
				
				$(".hidden_fields.billing").hide(); // Hide the new-address text fields.
				// $("input#billing_existing").parents("div.radio").css("background-color", "white"); // Highlight the radio's parent div.
				// $("input#billing_same, input#billing_new").parents("div.radio").css("background-color", "#f2f2f2"); // Darken the other radios' parent div.
				$("select#existing_billing_address").prop("disabled", false); // Enable the existing-address inputs.
				$("input.billing_new, select.billing_new").prop("disabled", true); // Disable the new-address inputs.
				$("span.required.billing").hide(); // Hide required-field indicators.
			}
			if($("input#billing_new").prop("checked"))
			{
				recent_billing = 'new';
				
				$(".hidden_fields.billing").show(); // Show the new-address text fields.
				// $("input#billing_new").parents("div.radio").css("background-color", "white"); // Highlight the radio's parent div.
				// $("input#billing_same, input#billing_existing").parents("div.radio").css("background-color", "#f2f2f2"); // Darken the other radios' parent div.
				$("input.billing_new, select.billing_new").prop("disabled", false); // Enable the new-address inputs.
				$("select#existing_billing_address").prop("disabled", true); // Disable the existing-address inputs.
				$("span.required.billing").show(); // Show required-field indicators.
			}
	    }
	    check_shipping_radios();
	    check_billing_radios();
	    
	    $("input.address.shipping").on("click", function()
	    {
			check_shipping_radios();
		});
		$("input.address.billing").on("click", function()
	    {
			check_billing_radios();
		});
		
		/**
	     * Delivery method.
	     */
		var update_delivery_option = function()
		{
			if($("input#delivery_courier").prop("checked"))
			{
				// Reset the shipping cost display.
				if(recent_shipping == 'existing')
				{
					get_existing();
				}
				if(recent_shipping == 'new')
				{
					get_new();
				}
				
				$(".radio.hidden").hide(); // Hide the existing address radio and select.
				
				$(".delivery-help").hide(); // Hide the delivery help text.
				
				$("input[name='shipping_address']").prop("disabled", false); // Enable the shipping address radios.
				$("input[name='shipping_address']").parents("div.radio").removeClass("disabled"); // Enable the cursor of the shipping address radios.
				$("input#billing_same").prop("disabled", false); // Enable the billing-same radio.
				$("input#billing_same").parents("div.radio").removeClass("disabled"); // Enable the cursor of the billing-same radios.
				$("input#billing_" + recent_billing).prop("checked", true); // Select the most recent billing radio.
				if(recent_billing != 'existing')
				{
					$("select#existing_billing_address").prop("disabled", true); // Disable the existing-address inputs.
				}
				$("input#billing_same").parents("div.radio").css("background-color", "white"); // Highlight the billing-same radio's parent div.
				$("input#shipping_existing").parents("div.radio").css("background-color", "white"); // Highlight the existing-address radio's parent div.
				$("input#shipping_new").parents("div.radio").css("background-color", "white"); // Highlight the new-address radio's parent div.
				
				if(recent_shipping == 'existing')
				{
					$("select#existing_shipping_address").prop("disabled", false); // Enable the existing-address inputs.
				}
				if(recent_shipping == 'new')
				{
					$(".hidden_fields.shipping").show(); // Show the new-address text fields.
					$("input.shipping_new, select.shipping_new").prop("disabled", false); // Enable the new-address inputs.
					$("span.required.shipping").show(); // Show required-field indicators.
				}
			}
			if($("input#delivery_pickup").prop("checked"))
			{
				set_pickup(); // Set the shipping cost display to pickup.
				
				$(".radio.hidden").show(); // Show the hidden existing address radio and select.
				
				$(".delivery-help").show(); // Show the delivery help text.
				
				$("input[name='shipping_address']").prop("disabled", true); // Disable the shipping address radios.
				$("input[name='shipping_address']").parents("div.radio").addClass("disabled"); // Disable the cursor of the shipping address radios.
				$("input#billing_same").prop("disabled", true); // Disable the billing-same radio.
				$("input#billing_same").parents("div.radio").addClass("disabled"); // Disable the cursor of the billing-same radio.
				$("input#billing_same").parents("div.radio").css("background-color", "#f2f2f2"); // Darken the billing-same radio's parent div.
				if(recent_billing == 'same')
				{
					$("input#billing_existing").prop("checked", true); // Select the billing-existing radio.
					$("select#existing_billing_address").prop("disabled", false); // Enable the existing-address inputs.
				}
				$("select#existing_shipping_address").prop("disabled", true); // Disable the existing-address inputs.
				$("input.shipping_new, select.shipping_new").prop("disabled", true); // Disable the new-address inputs.
				$(".hidden_fields.shipping").hide(); // Hide the new-address text fields.
				$("input#shipping_existing").parents("div.radio").css("background-color", "#f2f2f2"); // Darken the existing-address radio's parent div.
				$("input#shipping_new").parents("div.radio").css("background-color", "#f2f2f2"); // Darken the new-address radio's parent div.
				$("span.required.shipping").hide(); // Hide required-field indicators.
			}
		}
	    update_delivery_option();
	    
	    $("input.delivery").change(function()
		{
			update_delivery_option();
		});
		
	    /**
	     * Shipping cost display.
	     */
	    function set_national()
	    {
	    	// Determine whether the order qualifies for free shipping.
			var discounted = $("h3.price").data("discount");
	    	if(discounted)
			{
				// Set price displays.
				$("h3.price").html(nzd(subtotal));
				$("span.shipping_cost").html("Free within NZ");
				// Set form input values.
				// $("input[name='total']").val(subtotal);
				// $("input[name='shipping']").val(null);
			}
			else
			{
				// Set price displays.
				$("h3.price").html(nzd(subtotal + shipping));
				$("span.shipping_cost").html($("h3.price").data("shipping-nzd"));
				// Set form input values.
				// $("input[name='total']").val(subtotal + shipping);
				// $("input[name='shipping']").val(shipping);
			}
			
			$("p.subtitle").html("Total cost");
			$("p.shipping-help").hide();
	    }
	    function set_international()
	    {
	    	// Set price displays.
	    	$("h3.price").html(nzd(subtotal));
	    	$("span.shipping_cost").html("To be confirmed");
			$("p.subtitle").html("Plus shipping");
			$("p.shipping-help").show();
			// Set form input values.
			// $("input[name='total']").val(subtotal);
			// $("input[name='shipping']").val(null);
	    }
	    function set_pickup()
	    {
	    	// Set price displays.
	    	$("h3.price").html(nzd(subtotal));
	    	$("span.shipping_cost").html("None");
	    	$("p.subtitle").html("Total cost");
	    	$("p.shipping-help").hide();
	    	// Set form input values.
	    	// $("input[name='total']").val(subtotal);
	    	// $("input[name='shipping']").val(null);
	    }
		
	    // Existing address.
	    function get_existing()
		{
			// Get the value of the select.
			var selected_existing = $("select#existing_shipping_address option:selected").data('country');
			// Change the HTMl of the span based on the selected country.
			// if(selected_existing == "NZ")
			// {
				// set_national();
			// }
			// else
			// {
				set_international();
			// }
		}
		
		// New address.
		function get_new()
		{
			// Get the value of the select.
			var selected_new = $("select#shipping_country option:selected").val();
			// Change the HTMl of the span based on the selected country.
			// if(selected_new == "NZ")
			// {
				// set_national();
			// }
			// else
			// {
				set_international();
			// }
		}
		
		// On select change.
		$("select#existing_shipping_address").change
		(
			function()
			{
				get_existing();
			}
		);
		$("select#shipping_country").change
		(
			function()
			{
				get_new();
			}
		);
    }
	
	
	/**
     * TERMS.INDEX
     * -------------
     */
    if($("#terms-sections").length > 0)
	{
		/**
		 * Clause links.
		 */
		$("ol#clauses li.clause").each
		(
			function()
			{
				// Create a link to this clause.
				$("ul#term-links").show();
				$("ul#term-links").append("<li><a href='#" + $(this).attr('id') + "' id='anchor_" + $(this).attr('id') + "'>" + $(this).data('title') + "</a></li>");
			}
		);
		
		/**
		 * Clause link highlighting.
		 */
		$(window).scroll
		(
			function()
			{
				var scroll_pos = $(window).scrollTop();
				
				$("ol#clauses li.clause").each
				(
					function()
					{
						// Get the coordinates for this clause.
						var clause_top = Math.floor($(this).offset().top);
						if($(this).next().length > 0)
						{
							var clause_bottom = Math.floor($(this).next().offset().top);
						}
						else
						{
							var clause_bottom = clause_top + Math.floor($(this).outerHeight());
						}
						// Get the link for this clause.
						var clause_anchor = ($("a#anchor_" + $(this).attr('id')));
						// If the window is within the clause, highlight its link.
						if(scroll_pos >= clause_top && scroll_pos < clause_bottom)
						{
							clause_anchor.addClass("active");
						}
						// If the window is without the clause, remove the highlight from its link.
						else if(scroll_pos < clause_top || scroll_pos >= clause_bottom)
						{
							clause_anchor.removeClass("active");
						}
					}
				);
			}
		);
	}
	
});